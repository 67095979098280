import * as React from 'react';
import { useEffect } from 'react';
import { isUrlAbsolute } from '../utils/url';
import { isLanguageAvailable } from '@utils/localized-navigate';
import { useTranslation } from '@external/react-i18next';

const StaticRedirect = ({ pageContext }: any) => {
  const languageAvailable = isLanguageAvailable();
  const { i18n } = useTranslation();
  useEffect(() => {
    // When performing internal redirects, we want to keep the query parameters.
    if (isLanguageAvailable()) {
      let redirectPath = pageContext.redirectPath;
      if (!isUrlAbsolute(redirectPath)) {
        redirectPath = `${redirectPath}${window.location.search}`;
      }
      window.location.assign(redirectPath);
    }
  }, [i18n.language, languageAvailable]);
  return <></>;
};

export default StaticRedirect;
